import React from 'react'
import { Result, Table } from 'antd'
import { Col, Row } from 'reactstrap'
import moment from 'moment'

import { number, round } from '../../../helpers'

export default function SmsCampaignsTable({ campaigns }) {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Campaña',
            dataIndex: 'name'
        },
        {
            title: 'Segmento',
            dataIndex: 'segment_name',
        },
        {
            title: 'Solicitados',
            dataIndex: 'sms_requested_count',
            render: t => number(t)
        },
        {
            title: 'Entregados',
            dataIndex: 'sms_delivered_count',
            render: t => number(t)
        },
        {
            title: 'Fecha Envió',
            dataIndex: 'sent_at',
            render: t => moment(t).format('YYYY-MM-DD'),
            width: '120px'
        },
        {
            title: 'Sync',
            dataIndex: 'has_invoices_sync',
            render: t => t ? 'Finalizado' : 'Pend. Fcs',
            width: '90px'
        }
    ]

    const expandedRowRender = (r) => (
        <React.Fragment>
            <Row>
                <Col>
                    <p><strong>Mensaje: </strong>{r.message}</p>
                    <p><strong>Estadísticas actualizadas al: </strong>{r.statistics_calculated_at}</p>
                </Col>
                <Col>
                    <p><strong>Campaña valida hasta: </strong>{r.expire_at}</p>
                    <p><strong>Total Rebotes/Rechazos: </strong>{number(r.sms_requested_count - r.sms_delivered_count)} ({round((r.sms_requested_count - r.sms_delivered_count)/(r.sent_to_number_of_subscribers)*100)}%)</p>
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <Table
                dataSource={campaigns}
                rowKey={r => r.id}
                columns={columns}
                loading={!campaigns}
                locale={{ emptyText: <Result title="No se encontro resultados" status="404" /> }}
                expandable={{ expandedRowRender }}
                size="small"
            />
        </React.Fragment>
    )
}