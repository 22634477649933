import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'

import SmsCampaignsTable from './partials/SmsCampaignsTable'

import { campaignsGeneralReport } from './services'
import { handleError } from '../../helpers'

export default function GeneralSMSCampaignsReport() {
    const [smsCampaigns, setSmsCampaigns] = useState(null)

    useEffect(() => {
        !smsCampaigns && campaignsGeneralReport({
            'report_type': 'sms_campaigns'
        })
            .then(res => setSmsCampaigns(res.data.data.data))
            .catch(error => handleError(error))
    }, [ smsCampaigns ])

    return (
        <React.Fragment>
            <Card>
				<CardBody>
					<div className='float-right'>
						<Link to="/dashboards/campaigns">
							<Button color="primary" outline>Ver otros reportes</Button>
						</Link>
					</div>
					<CardTitle>Reporte General de Campañas SMS</CardTitle>
					<CardSubtitle>Campañas de sms enviadas masivamente</CardSubtitle>
				</CardBody>
			</Card>
            <Card>
				<CardBody>
                    <SmsCampaignsTable 
                        campaigns={smsCampaigns}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )
}