import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import GeneralEmailCampaignsReport from './GeneralEmailCampaignsReport'
import GeneralSMSCampaignsReport from './GeneralSMSCampaignsReport'
import AdvanceCampaignsReport from './AdvanceCampaignsReport'

export default function EmailCampaigns() {
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/campaigns/email/general" component={GeneralEmailCampaignsReport} />
                <Route exact path="/campaigns/sms/general" component={GeneralSMSCampaignsReport} />
                <Route exact path="/campaigns/email/advance" render={() => <AdvanceCampaignsReport reportType="email_campaigns" />} />
                <Route exact path="/campaigns/sms/advance" render={() => <AdvanceCampaignsReport reportType="sms_campaigns" />} />

                <Redirect to="/404" />
            </Switch>
        </React.Fragment>
    )
}