import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faBarChart, faMailBulk, faPaperPlane, faSms } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function CampaignsDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Ventas Momentos Spring" subtitle="Reportes relacionados a las ventas asociadas al programa de Momentos Spring." />
			<Row>
				<Col sm="12" md="6" lg="6" className="mb-10">
					<MenuWidget 
						title="Reporte General Campañas Email"
						subtitle="Listado de Campañas de Email Marketing"
						icon={faPaperPlane}
						linkTo={"/campaigns/email/general"}
					/>
				</Col>
				<Col sm="12" md="6" lg="6" className="mb-10">
					<MenuWidget 
						title="Reporte Avanzado Email Marketing"
						subtitle="Análisis de Campañas de Email Marketing Momentos Spring"
						icon={faMailBulk}
						linkTo={"/campaigns/email/advance"}
					/>
				</Col>
				<Col sm="12" md="6" lg="6" className="mb-10">
					<MenuWidget 
						title="Reporte General Campañas SMS"
						subtitle="Listado de Campañas de SMS"
						icon={faBarChart}
						linkTo={"/campaigns/sms/general"}
					/>
				</Col>
				<Col sm="12" md="6" lg="6" className="mb-10">
					<MenuWidget 
						title="Reporte Avanzado Campañas SMS"
						subtitle="Análisis de Campañas SMS Momentos Spring"
						icon={faSms}
						linkTo={"/campaigns/sms/advance"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}