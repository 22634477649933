import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Empty, message, Statistic, Card } from 'antd'

import EmailCampaignsTable from '../EmailCampaignsTable'
import SmsCampaignsTable from '../SmsCampaignsTable'

import { marketingKpisReport } from '../../services'
import { handleError, number } from '../../../../helpers'

export default function MarketingKpisTab({ reports, setReports, filters, activeKey, setLoading }) {
    const [currentParams, setCurrentParams] = useState(null)
    
    useEffect(() => {
        if(activeKey === 'marketing_kpis' && (!reports || !reports.marketing_kpis) && filters.sent_at_from){
            marketingKpisReport(filters)
                .then(res => {
                    message.success(res.data.message)
                    setReports({ ...reports, marketing_kpis: res.data.data })
                    setCurrentParams(res.data.params)
                })
                .catch(error => handleError(error))
                .finally(() => setLoading(false))
        }
    }, [ filters, activeKey, reports, setReports, setLoading ])

    if(!filters.sent_at_from)
        return <Empty description="Debe generar el reporte primero" />

    if(!reports || !reports.marketing_kpis)
        return <Spinner animation='border' size='sm' />

    return (
        <React.Fragment>
            <div className='bg-light py-5 px-15 mb-10'>
                <em>Reporte generado para campañas con fecha de envió entre <b>{currentParams.sent_at_from}</b> y <b>{currentParams.sent_at_until}</b> {currentParams.store_code && `Y facturas de tienda cuyo codigo contenga: ${currentParams.store_code}`}</em>. {reports.marketing_kpis.campaigns.length} campañas encontradas.
            </div>
            { filters.report_type === 'email_campaigns' && (
                <EmailCampaignsTable
                    campaigns={reports.marketing_kpis.campaigns} 
                />
            )}
            { filters.report_type === 'sms_campaigns' && (
                <SmsCampaignsTable
                    campaigns={reports.marketing_kpis.campaigns} 
                />
            )}
            { reports.marketing_kpis.campaigns.length > 0 && (
                <Row className='bg-light p-10'>
                    <Col sm="6" md="4">
                        <Card bordered={false}>
                            <Statistic 
                                title="Total Cancelaciones de Suscripción"
                                value={number(reports.marketing_kpis.totals.total_usubscribed)}
                            />
                        </Card>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    )
}